import axios from 'axios'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import { auth } from '../firebase'

const $axios = axios.create({
  baseURL: 'https://us-central1-henlee-henlly.cloudfunctions.net/',
})

// リクエストインターセプト
$axios.interceptors.request.use(
  async (config) => {
    // console.log('Request:', config)
    // TODO: 新規登録時には、emailVerifiedがfalseになっている（フロント側はtrueになっている）ため、強制的にRefreshする。
    // しかし、毎回Refreshするのは、パフォーマンス的に良くないのため、本来は、新規登録時のみ必要
    // TODO: ただし、2023/06/12時点では一旦新規登録のみのため、一旦、getIdTokenの引数をtrueにする。他の処理が入ってきたら、trueを消し、新規登録時のみtrueになるよう調整する。
    // TODO: 恐らく、config.urlの末尾が`userCreate`の場合のみ、trueにすると良いと思われる。
    const token = await auth.currentUser?.getIdToken(true)
    if (token && config.headers) {
      config.headers.authorization = `Bearer ${token}`
    }
    // console.log(JSON.stringify(config, null, 2))
    if (typeof config.data === 'string') {
      config.data = JSON.parse(config.data)
    }
    config.data = snakecaseKeys(config.data, { deep: true })
    return config
  },
  (error) => {
    // リクエストエラー時の処理
    console.error('Request Error:', error)
    return Promise.reject(error)
  }
)

// レスポンスインターセプト
$axios.interceptors.response.use(
  (response) => {
    // console.log('Response:', response)
    // ここで response を変更したり、ログを出力したりすることが可能です。
    if (!response.data || typeof response.data !== 'object') {
      return response
    }
    response.data = camelcaseKeys(response.data, { deep: true })
    return response
  },
  (error) => {
    // レスポンスエラー時の処理
    console.error('Response Error:', error)
    // console.log(JSON.stringify(error, null, 2))
    if (!error.data || typeof error.data !== 'object') {
      return error
    }
    error.data = camelcaseKeys(error.data, { deep: true })
    return Promise.reject(error)
  }
)

export default $axios
