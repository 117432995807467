import React from 'react'
import './app-input.scss'
import { FieldError, FieldValues } from 'react-hook-form'

type Props = {
  label: string
  register?: FieldValues
  errors?: FieldError
}

const AppInput: React.FunctionComponent<Props> = ({
  label,
  register,
  errors,
}) => {
  return (
    <div className="app-input">
      <label className="label">{label}</label>
      {register ? (
        <input className="input" {...register} />
      ) : (
        <input className="input" />
      )}
      <p className="error">{errors?.message}</p>
    </div>
  )
}

export default AppInput
