import { Outlet } from 'react-router-dom'

const MainLayout = () => {
  return (
    <div className="main-layout-container">
      <div>main-layout</div>
      <Outlet />
    </div>
  )
}

export default MainLayout
