import React, { useState } from 'react'
import { useRef } from 'react'
import { SwitchLoaderArgs } from '../../../../types/props-types'
import './app-loader.scss'
import './sk-chase.scss'

type Props = {
  setLoaderState: SwitchLoaderArgs
  isLayout?: boolean
}
export const AppLoader: React.FunctionComponent<Props> = ({
  setLoaderState,
  isLayout = false,
}) => {
  const loaderRef = useRef<HTMLDivElement>(null)
  const loaderId = isLayout ? 'layout-loader' : 'page-loader'
  const loaderClassNames = [
    'app-loader',
    setLoaderState.isTransparent ? '-transparent' : '',
  ].join(' ')
  const loaderStyle = {
    display: setLoaderState.isLoading ? 'flex' : 'none',
  }

  return (
    <div
      id={loaderId}
      ref={loaderRef}
      className={loaderClassNames}
      style={loaderStyle}
    >
      <div className="loader">
        <div className="sk-chase">
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
        </div>
        <p className="text">処理中です...</p>
      </div>
    </div>
  )
}

export const useLoaderState = () => {
  /**
   * ローディングState
   */
  const [loaderState, setLoaderState] = useState<SwitchLoaderArgs>({
    isLoading: false,
    isTransparent: false,
  })

  /**
   * ローディング画面の表示
   * @param isLoading
   * @param isTransparent
   */
  const switchState = ({
    isLoading,
    isTransparent = false,
  }: SwitchLoaderArgs) => {
    setLoaderState({ isLoading, isTransparent })
  }

  return { loaderState, switchState }
}
