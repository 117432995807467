import React from 'react'
import './apply-confirm.scss'

const ApplyConfirm: React.FunctionComponent = () => {
  return (
    <div className="apply-confirm">
      <div className="title">
        <h1 className="h1">仮申し込み完了</h1>
        <p className="notice">メールをお送りしましたので、ご確認ください。</p>
      </div>
    </div>
  )
}

export default ApplyConfirm
