import React from 'react'
import './apply-container.scss'
import './apply-agreement.scss'
import AppLink from '../../../components/atoms/Link/AppLink/AppLink'
import AppInput from '../../../components/atoms/Inputs/AppInput/AppInput'
import { useForm } from 'react-hook-form'
import AppButton from '../../../components/atoms/Button/AppButton/AppButton'
import AppPasswordInput from '../../../components/atoms/Inputs/AppPasswordInput/AppPasswordInput'
import {
  useLoaderState,
  AppLoader,
} from '../../../components/ui/Loader/AppLoader/AppLoader'
import { useUrls } from '../../../App'
import { useNavigate } from 'react-router-dom'
import {
  BackendErrors,
  backendErrorsState,
} from '../../../components/molecules/Errors/BackendErrors/BackendErrors'
import { auth } from '../../../lib/firebase'
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from '@firebase/auth'

type SignupInputs = {
  email: string
  password: string
}
const Apply: React.FunctionComponent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<SignupInputs>({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onBlur',
  })
  const navigate = useNavigate()
  const { loaderState, switchState } = useLoaderState()
  const { backendErrors, setBackendErrors } = backendErrorsState()

  /** 利用申し込み */
  const submit = async (payload: SignupInputs) => {
    try {
      switchState({ isLoading: true, isTransparent: true })
      const { user } = await createUserWithEmailAndPassword(
        auth,
        payload.email,
        payload.password
      )
      await sendEmailVerification(user, {
        url: `${location.protocol}//${location.host}${useUrls.applyComplete}`,
      })
      navigate(useUrls.applyConfirm)
    } catch (e) {
      setBackendErrors(e, 500)
      navigate(useUrls.applyConfirm)
    } finally {
      switchState({ isLoading: false })
    }
  }

  return (
    <div className="apply-container">
      <AppLoader setLoaderState={loaderState} />
      <div className="title">
        <h1 className="h1">利用申し込み</h1>
        <p className="notice">
          現在申込みいただくと、リリース時に特別なご連絡を差し上げます。
        </p>
        <div className="desc">
          <AppLink to={'/'}>サービスについての説明はこちら</AppLink>
        </div>
      </div>
      <div className="main">
        <form className="form" onSubmit={handleSubmit(submit)}>
          <BackendErrors errors={backendErrors} />
          <div className="inputs">
            <AppInput
              label="メールアドレス"
              errors={errors.email}
              register={register('email', {
                required: 'メールアドレスは必須です',
                pattern: {
                  value: /^[\w\-._+]+@[\w\-._]+\.[A-Za-z]+/,
                  message: '入力形式がメールアドレスではありません',
                },
                maxLength: {
                  value: 255,
                  message: 'メールアドレスは255文字以下です',
                },
              })}
            />
            <AppPasswordInput
              label="パスワード"
              errors={errors.password}
              isShowNotice={true}
              register={register('password', {
                required: 'パスワードは必須です',
                minLength: { value: 6, message: 'パスワードは6文字以上です' },
                maxLength: { value: 32, message: 'パスワードは32文字以下です' },
              })}
            />
          </div>
          <div className="apply-agreement">
            <p className="agreement">
              <AppLink to={useUrls.termsOfService}>利用規約</AppLink>と
              <AppLink to={useUrls.privacyPolicy}>プライバシーポリシー</AppLink>
              に同意し、申し込む。
            </p>
            <AppButton type={'submit'} disabled={!isValid}>
              申し込む
            </AppButton>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Apply
