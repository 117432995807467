import React, { useState } from 'react'
import { AxiosError } from 'axios'
import './backend-errors.scss'

type Props = {
  errors?: string[]
}
export const BackendErrors: React.FunctionComponent<Props> = ({
  errors = [],
}) => {
  const errorsStyle = {
    display: errors.length > 0 ? 'block' : 'none',
  }

  return (
    <ul style={errorsStyle} id="backend-errors" className="backend-errors">
      {errors.map((error, index) => (
        <li key={index} className="error">{`・${error}`}</li>
      ))}
    </ul>
  )
}

export const backendErrorsState = () => {
  const [errors, setErrors] = useState<string[]>([])

  /** エラーの検証をします */
  const setBackendErrors = (err: unknown, status = 400) => {
    if (!(err instanceof AxiosError)) return
    if (err.response?.status !== status || !err.response?.data?.message) return
    setErrors([err.response.data.message])
  }

  return { backendErrors: errors, setBackendErrors }
}
