import React from 'react'
import './list-text.scss'

type Props = {
  title: string
  children: React.ReactNode
}

const ListText: React.FunctionComponent<Props> = ({ title, children }) => {
  return (
    <div className="list-text">
      <h2 className="h2">{title}</h2>
      <div className="body">{children}</div>
    </div>
  )
}

export default ListText
