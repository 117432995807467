import React from 'react'
import './app-password-input.scss'
import './app-eye.scss'
import { FieldError, FieldValues } from 'react-hook-form'

type Props = {
  label: string
  register: FieldValues
  errors?: FieldError
  isShowNotice?: boolean
}

const AppPasswordInput: React.FunctionComponent<Props> = ({
  label,
  register,
  errors,
  isShowNotice = false,
}) => {
  const [isTypePassword, setIsTypePassword] = React.useState(true)
  const handleClick = () => {
    setIsTypePassword(!isTypePassword)
  }
  return (
    <div className="app-password-input">
      <label className="label">{label}</label>
      <input
        className="input"
        type={isTypePassword ? 'password' : 'text'}
        {...register}
      />
      <div
        className={'app-eye'}
        onClick={handleClick}
        style={{
          backgroundImage: isTypePassword
            ? 'url(/images/open_eye.svg)'
            : 'url(/images/close_eye.svg)',
        }}
      />
      <p
        className="notice"
        style={{ display: isShowNotice ? 'block' : 'none' }}
      >
        半角英数８文字以上
      </p>
      <p className="error">{errors?.message}</p>
    </div>
  )
}

export default AppPasswordInput
