import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from '@firebase/auth'

/** henlee-henlly */
// TODO: しばらくの間は環境によって読み込むenvファイルを切り分ける
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

if (location.hostname === 'localhost' && !auth.emulatorConfig?.host) {
  connectAuthEmulator(auth, process.env.REACT_APP_FB_AUTH_HOST || '')
}

export { auth }
