import { Outlet } from 'react-router-dom'
import './guest-layout-container.scss'

const GuestLayout = () => {
  return (
    <div className="guest-layout-container">
      <div className="body">
        <div className="head">
          <img className="image" src="/icon/app-logo.svg" alt="app-logo" />
        </div>
        <div className="component">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default GuestLayout
