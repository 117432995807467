import React from 'react'
import './not-found.scss'

const NotFound: React.FunctionComponent = () => {
  return (
    <div className="not-found">
      <p className="message">404 ページが見つかりません</p>
    </div>
  )
}

export default NotFound
