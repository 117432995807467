/* tslint:disable */
/* eslint-disable */
/**
 * henlee-henlly-api
 * API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BadRequestException
 */
export interface BadRequestException {
    /**
     * エラーメッセージ
     * @type {string}
     * @memberof BadRequestException
     */
    message: string | null;
    /**
     * エラーコード
     * @type {string}
     * @memberof BadRequestException
     */
    code: string;
}
/**
 * 
 * @export
 * @interface ConflictException
 */
export interface ConflictException {
    /**
     * エラーメッセージ
     * @type {string}
     * @memberof ConflictException
     */
    message: string | null;
    /**
     * エラーコード
     * @type {string}
     * @memberof ConflictException
     */
    code: string;
}
/**
 * 
 * @export
 * @interface ForbiddenException
 */
export interface ForbiddenException {
    /**
     * エラー内容のメッセージ
     * @type {string}
     * @memberof ForbiddenException
     */
    message: string;
    /**
     * エラーコード
     * @type {string}
     * @memberof ForbiddenException
     */
    code: string;
}
/**
 * 
 * @export
 * @interface NotFoundException
 */
export interface NotFoundException {
    /**
     * エラー内容のメッセージ
     * @type {string}
     * @memberof NotFoundException
     */
    message: string;
    /**
     * エラーコード
     * @type {string}
     * @memberof NotFoundException
     */
    code: string;
}
/**
 * 
 * @export
 * @interface TooManyRequestsException
 */
export interface TooManyRequestsException {
    /**
     * エラーメッセージ
     * @type {string}
     * @memberof TooManyRequestsException
     */
    message: string | null;
    /**
     * エラーコード
     * @type {string}
     * @memberof TooManyRequestsException
     */
    code: string;
    /**
     * 
     * @type {TooManyRequestsExceptionLockout}
     * @memberof TooManyRequestsException
     */
    lockout: TooManyRequestsExceptionLockout;
}
/**
 * 
 * @export
 * @interface TooManyRequestsExceptionLockout
 */
export interface TooManyRequestsExceptionLockout {
    /**
     * 秒数
     * @type {number}
     * @memberof TooManyRequestsExceptionLockout
     */
    seconds: number;
    /**
     * 分数
     * @type {number}
     * @memberof TooManyRequestsExceptionLockout
     */
    minutes: number;
}
/**
 * 
 * @export
 * @interface UnauthorizedException
 */
export interface UnauthorizedException {
    /**
     * エラー内容のメッセージ
     * @type {string}
     * @memberof UnauthorizedException
     */
    message: string;
    /**
     * エラーコード
     * @type {string}
     * @memberof UnauthorizedException
     */
    code: string;
}
/**
 * 
 * @export
 * @interface UserCreateParameter
 */
export interface UserCreateParameter {
    /**
     * メールアドレス
     * @type {string}
     * @memberof UserCreateParameter
     */
    email: string;
    /**
     * FirebaseAuthenticationのユーザーID
     * @type {string}
     * @memberof UserCreateParameter
     */
    fbUserId: string;
}

/**
 * UserAuthApi - axios parameter creator
 * @export
 */
export const UserAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ユーザーの新規登録を行います。
         * @summary ユーザー登録
         * @param {UserCreateParameter} [userCreateParameter] ユーザー登録
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreate: async (userCreateParameter?: UserCreateParameter, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/userCreate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateParameter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserAuthApi - functional programming interface
 * @export
 */
export const UserAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * ユーザーの新規登録を行います。
         * @summary ユーザー登録
         * @param {UserCreateParameter} [userCreateParameter] ユーザー登録
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userCreate(userCreateParameter?: UserCreateParameter, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userCreate(userCreateParameter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserAuthApi - factory interface
 * @export
 */
export const UserAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserAuthApiFp(configuration)
    return {
        /**
         * ユーザーの新規登録を行います。
         * @summary ユーザー登録
         * @param {UserCreateParameter} [userCreateParameter] ユーザー登録
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreate(userCreateParameter?: UserCreateParameter, options?: any): AxiosPromise<object> {
            return localVarFp.userCreate(userCreateParameter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserAuthApi - object-oriented interface
 * @export
 * @class UserAuthApi
 * @extends {BaseAPI}
 */
export class UserAuthApi extends BaseAPI {
    /**
     * ユーザーの新規登録を行います。
     * @summary ユーザー登録
     * @param {UserCreateParameter} [userCreateParameter] ユーザー登録
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuthApi
     */
    public userCreate(userCreateParameter?: UserCreateParameter, options?: any) {
        return UserAuthApiFp(this.configuration).userCreate(userCreateParameter, options).then((request) => request(this.axios, this.basePath));
    }
}


