import React, { useEffect } from 'react'
import './apply-complete.scss'
import {
  AppLoader,
  useLoaderState,
} from '../../../components/ui/Loader/AppLoader/AppLoader'
import { auth } from '../../../lib/firebase'
import { wait } from '@testing-library/user-event/dist/utils'
import { useNavigate } from 'react-router-dom'
import { UserAuthApi } from '../../../lib/api'
import $axios from '../../../lib/api/axios'

const ApplyComplete: React.FunctionComponent = () => {
  const { loaderState, switchState } = useLoaderState()
  const navigate = useNavigate()
  const api = new UserAuthApi(undefined, undefined, $axios)
  useEffect(() => {
    const confirmUser = async () => {
      try {
        switchState({ isLoading: true, isTransparent: true })
        /** FirebaseAuthのユーザー情報が初回描画時に非同期で取得するため1秒だけ猶予を持っている */
        for (let i = 0; i < 10; i++) {
          await wait(100)
          if (auth.currentUser) break
        }

        if (!auth.currentUser || !auth.currentUser?.emailVerified) {
          throw new Error('ユーザーが取得できませんでした')
        }

        await api.userCreate({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          email: auth.currentUser!.email!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          fbUserId: auth.currentUser!.uid!,
        })
      } catch (e) {
        /** ユーザーが取得できない場合は完了画面は見せたくないので404へ */
        console.error(e)
        navigate('/404')
      } finally {
        switchState({ isLoading: false })
      }
    }
    confirmUser()
  }, [])

  return (
    <div className="apply-complete">
      <AppLoader setLoaderState={loaderState} />
      <div className="title">
        <h1 className="h1">申し込み完了</h1>
        <p className="notice">
          リリース時に特別なご連絡をいたします！メールの通知をお待ちください。
        </p>
      </div>
    </div>
  )
}

export default ApplyComplete
