import React from 'react'
import './App.css'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Apply from './pages/Auth/Apply/Apply'
import MainLayout from './layouts/MainLayout/MainLayout'
import GuestLayout from './layouts/GuestLayout/GuestLayout'
import './styles/scss/main.scss'
import NotFound from './pages/Errors/NotFound/NotFound'
import ApplyComplete from './pages/Auth/ApplyComplete/ApplyComplete'
import ApplyConfirm from './pages/Auth/ApplyConfirm/ApplyConfirm'
import PrivacyPolicy from './pages/Terms/PrivacyPolicy/PrivacyPolicy'
import TermsOfService from './pages/Terms/TermsOfService/TermsOfService'

export function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<GuestLayout />}>
          <Route path="/apply" element={<Apply />} />
          <Route path="/apply-confirm" element={<ApplyConfirm />} />
          <Route path="/apply-email-confirm" element={<ApplyConfirm />} />
          <Route path="/apply-complete" element={<ApplyComplete />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
        </Route>
        <Route element={<MainLayout />}>
          {/* しばらくは"/"にアクセスが来ても"/apply"へ遷移する */}
          <Route path="/" element={<Navigate to={'/apply'} />} />
        </Route>
        <Route element={<GuestLayout />}>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export const useUrls = {
  apply: '/apply',
  applyConfirm: '/apply-confirm',
  applyComplete: '/apply-complete',
  privacyPolicy: '/privacy-policy',
  termsOfService: '/terms-of-service',
}
