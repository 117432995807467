import React from 'react'
import './app-button.scss'
import { useNavigate } from 'react-router-dom'

type Props = {
  type: 'button' | 'submit' | 'reset'
  disabled?: boolean
  to?: string
  children: React.ReactNode
}
const AppButton: React.FunctionComponent<Props> = ({
  type = 'button',
  disabled,
  to,
  children,
}) => {
  const navigate = useNavigate()
  const handlerClick = () => {
    if (to) navigate(to)
  }

  const className = disabled ? 'app-button -disabled' : 'app-button'

  return (
    <button
      className={className}
      type={type}
      onClick={handlerClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default AppButton
