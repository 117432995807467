import React from 'react'
import './app-link.scss'
import { Link } from 'react-router-dom'

type Props = {
  to: string
  children: React.ReactNode
}
const AppLink: React.FunctionComponent<Props> = ({ to, children }) => {
  return (
    <Link to={to} className="app-link">
      {children}
    </Link>
  )
}

export default AppLink
